// eslint-disable-next-line no-unused-vars
import React, { Suspense, lazy, useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
const records = lazy(() => import('./view/patient/records'));
const Welcome = lazy(() => import('./view/patient/Learn Module/Welcome'));
const ModulesPage = lazy(() =>
  import('./view/patient/Learn Module/ModulesPage')
);
const Video = lazy(() => import('./view/patient/Learn Module/video'));
const ReflectionModule = lazy(() =>
  import('./view/patient/Learn Module/ReflectionModule')
);
const ReflectionModule3 = lazy(() =>
  import('./view/patient/Learn Module/ReflectionModule3')
);
const ReflectionModule4 = lazy(() =>
  import('./view/patient/Learn Module/ReflectionModule4')
);
const ReflectionAnswers = lazy(() =>
  import('./view/patient/Learn Module/ReflectionAnswers')
);
const ActionModule = lazy(() =>
  import('./view/patient/Learn Module/ActionModule')
);
const ActionModule3Intro = lazy(() =>
  import('./view/patient/Learn Module/ActionModule3Intro')
);
const ActionModule3Main = lazy(() =>
  import('./view/patient/Learn Module/ActionModule3Main')
);
const ActionModule4Main = lazy(() =>
  import('./view/patient/Learn Module/ActionModule4Main')
);
const ModuleHeaderViewPage = lazy(() =>
  import('./view/patient/Learn Module/ModuleHeaderViewPage')
);
const ModuleProgress = lazy(() =>
  import('./view/patient/Learn Module/ModuleProgress')
);
const DisplayVideo = lazy(() =>
  import('./view/patient/Learn Module/DisplayVideo')
);

const ActionModule4Intro = lazy(() =>
  import('./view/patient/Learn Module/ActionModule4Intro')
);
const ActionModule7 = lazy(() =>
  import('./view/patient/Learn Module/ActionModule7')
);
const ActionModule8 = lazy(() =>
  import('./view/patient/Learn Module/ActionModule8')
);
const ActionModule9 = lazy(() =>
  import('./view/patient/Learn Module/ActionModule9')
);
const ActionModule9a = lazy(() =>
  import('./view/patient/Learn Module/ActionModule9a')
);
const ActionModule9b = lazy(() =>
  import('./view/patient/Learn Module/ActionModule9b')
);
const ActionModule9c = lazy(() =>
  import('./view/patient/Learn Module/ActionModule9c')
);
const ActionModule9d = lazy(() =>
  import('./view/patient/Learn Module/ActionModule9d')
);
const ActionModule9e = lazy(() =>
  import('./view/patient/Learn Module/ActionModule9e')
);

const MobileRoutesRenderer = () => {
  return (
    <Switch>
      <Route exact path="/" component={ModulesPage} />
      <Route exact path="/patient/records" component={records} />

      <Route exact path="/patient/learn module/welcome" component={Welcome} />
      <Route
        exact
        path="/patient/learn_module/action"
        component={ActionModule}
      />
      <Route exact path="/patient/learn module/video" component={Video} />

      <Route exact path="/patient/records" component={records} />

      <Route exact path="/patient/learn module/welcome" component={Welcome} />
      <Route
        exact
        path="/patient/learn_module/action"
        component={ActionModule}
      />
      <Route exact path="/patient/learn module/video" component={Video} />
      <Route exact path="/patient/learn module/main" component={ModulesPage} />
      <Route
        exact
        path="/patient/learn module/main/preview"
        component={ModuleHeaderViewPage}
      />
      <Route
        exact
        path="/patient/learn module/progress"
        component={ModuleProgress}
      />
      <Route
        exact
        path="/patient/learn_module/reflection/quiz"
        component={ReflectionModule}
      />
      <Route
        exact
        path="/patient/learn_module/module3/reflection/quiz"
        component={ReflectionModule3}
      />
      <Route
        exact
        path="/patient/learn_module/module3/action"
        component={ActionModule3Intro}
      />
      <Route
        exact
        path="/patient/learn_module/module3/action/main"
        component={ActionModule3Main}
      />
      <Route
        exact
        path="/patient/learn_module/module4/reflection/quiz"
        component={ReflectionModule4}
      />
      <Route
        exact
        path="/patient/learn_module/module4/action/main"
        component={ActionModule4Main}
      />
      <Route
        exact
        path="/patient/learn_module/module4/action/intro"
        component={ActionModule4Intro}
      />
      <Route
        exact
        path="/patient/learn_module/module7/action/main"
        component={ActionModule7}
      />
      <Route
        exact
        path="/patient/learn_module/module7/action/main"
        component={ActionModule7}
      />

      <Route
        exact
        path="/patient/learn_module/module8/action/main"
        component={ActionModule8}
      />

      <Route
        exact
        path="/patient/learn_module/module9/action/main"
        component={ActionModule9}
      />
      <Route
        exact
        path="/patient/learn_module/module9/action/main/a"
        component={ActionModule9a}
      />
      <Route
        exact
        path="/patient/learn_module/module9/action/main/b"
        component={ActionModule9b}
      />
      <Route
        exact
        path="/patient/learn_module/module9/action/main/c"
        component={ActionModule9c}
      />
      <Route
        exact
        path="/patient/learn_module/module9/action/main/d"
        component={ActionModule9d}
      />
      <Route
        exact
        path="/patient/learn_module/module9/action/main/e"
        component={ActionModule9e}
      />
      <Route exact path="/patient/view/answer" component={ReflectionAnswers} />
      <Route exact path="/patient/view/answer/video" component={DisplayVideo} />
      <Route exact path="/patient/learn_module/video" component={Video} />

      <Route render={() => <Redirect to={{ pathname: '/' }} />} />
    </Switch>
  );
};

export default MobileRoutesRenderer;
