import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import combineReducer from './index';
import { composeWithDevTools } from 'redux-devtools-extension';

const middleware = [thunk];

const store = createStore(
  combineReducer,
  compose(
    applyMiddleware(...middleware) // for production
    // composeWithDevTools(applyMiddleware(...middleware)) // for dev
  )
);
export default store;
